import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag-next";

const app = createApp(App);

app.use(router)
app.use(VueGtag, {
	property: {
		id: process.env.VUE_APP_GOOGLE_ANALYTICS
	}
});
app.mount('#app')
