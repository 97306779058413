<template>
  <nav class="sticky top z-10 w-screen flex flex-col sm:flex-row items-center sm:items-start sm:justify-between py-4 lg:py-10 px-4 lg:px-8 2xl:px-24 text-white z-20">
    <router-link to="/">
      <img class="mx-auto sm:mx-0 w-1/2 sm:w-1/5 lg:w-72 mb-8 sm:mb-0" src="/img/mdmelo.png" alt="MDMelo"/>
    </router-link>

    <div class="flex lg:flex-col lg:items-end gap-5">
      <router-link class="navigation-item" to="/">Latest</router-link>
      <router-link class="navigation-item" to="/tunes">Tunes</router-link>
      <router-link class="navigation-item" to="/about">About</router-link>
      <router-link class="navigation-item" to="/news">News</router-link>
    </div>
  </nav>
</template>

<script>

export default {
  name: "Navbar",

  components: {}
}
</script>

<style scoped lang="postcss">
  .navigation-item {
    @apply flex-1 text-lg pr-1 pb-1 leading-none cursor-pointer rounded-br-lg border-r-2 border-b-2 border-white transition-all duration-300;

    &:focus, &:hover, &.router-link-active {
      @apply border-orange-400 text-orange-400;
    }
  }
</style>
