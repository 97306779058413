<template>
  <footer
      class="absolute bottom-0 z-10 w-screen py-3 lg:py-6 px-4 lg:px-8 2xl:px-24 flex flex-col sm:flex-row items-center lg:items-baseline sm:justify-between text-white tracking-wider text-base"
      :class="{'bg-black bg-opacity-70': $route.path !== '/tunes'}">
    <div
        class="flex-1 flex flex-wrap flex-row items-center justify-center sm:justify-start gap-4 text-sm lg:text-lg mb-3 sm:mb-0">
      <a href="https://sptfy.com/mdmelo" target="_blank" class="social-link social-link-spotify">
        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1333.33 1333.3"
             shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality"
             fill-rule="evenodd" clip-rule="evenodd">
          <path
              d="M666.66 0C298.48 0 0 298.47 0 666.65c0 368.19 298.48 666.65 666.66 666.65 368.22 0 666.67-298.45 666.67-666.65C1333.33 298.49 1034.88.03 666.65.03l.01-.04zm305.73 961.51c-11.94 19.58-37.57 25.8-57.16 13.77-156.52-95.61-353.57-117.26-585.63-64.24-22.36 5.09-44.65-8.92-49.75-31.29-5.12-22.37 8.84-44.66 31.26-49.75 253.95-58.02 471.78-33.04 647.51 74.35 19.59 12.02 25.8 37.57 13.77 57.16zm81.6-181.52c-15.05 24.45-47.05 32.17-71.49 17.13-179.2-110.15-452.35-142.05-664.31-77.7-27.49 8.3-56.52-7.19-64.86-34.63-8.28-27.49 7.22-56.46 34.66-64.82 242.11-73.46 543.1-37.88 748.89 88.58 24.44 15.05 32.16 47.05 17.12 71.46V780zm7.01-189.02c-214.87-127.62-569.36-139.35-774.5-77.09-32.94 9.99-67.78-8.6-77.76-41.55-9.98-32.96 8.6-67.77 41.56-77.78 235.49-71.49 626.96-57.68 874.34 89.18 29.69 17.59 39.41 55.85 21.81 85.44-17.52 29.63-55.89 39.4-85.42 21.8h-.03z"
              class="fill-current" fill="#1ed760" fill-rule="nonzero"/>
        </svg>
        <span class="hidden md:block">Spotify</span>
      </a>
      <a href="https://www.facebook.com/maudiolabs/" target="_blank" class="social-link social-link-facebook">
        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333333 333333"
             shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality"
             fill-rule="evenodd" clip-rule="evenodd">
          <path
              d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm18069 106436h30115V70296h-30115c-23244 0-42158 18913-42158 42158v18070h-24095v36143h24089v96371h36142v-96371h30115l6022-36142h-36137v-18070c0-3266 2757-6022 6022-6022v4z"
              class="fill-current"/>
        </svg>
        <span class="hidden md:block">Facebook</span>
      </a>
      <a href="https://www.instagram.com/maudiolabs" target="_blank" class="social-link social-link-instagram">
        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333333 333333"
             shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality"
             fill-rule="evenodd" clip-rule="evenodd">
          <defs>
            <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="250181" y1="308196" x2="83152.4" y2="25137">
              <stop offset="0" stop-color="#f58529"/>
              <stop offset=".169" stop-color="#feda77"/>
              <stop offset=".478" stop-color="#dd2a7b"/>
              <stop offset=".78" stop-color="#8134af"/>
              <stop offset="1" stop-color="#515bd4"/>
            </linearGradient>
          </defs>
          <path
              d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm-40642 71361h81288c30526 0 55489 24654 55489 54772v81069c0 30125-24963 54771-55488 54771l-81289-1c-30526 0-55492-24646-55492-54771v-81069c0-30117 24966-54771 55492-54771zm40125 43843c29663 0 53734 24072 53734 53735 0 29667-24071 53735-53734 53735-29672 0-53739-24068-53739-53735 0-29663 24068-53735 53739-53735zm0 18150c19643 0 35586 15939 35586 35585 0 19647-15943 35589-35586 35589-19650 0-35590-15943-35590-35589s15940-35585 35590-35585zm51986-25598c4819 0 8726 3907 8726 8721 0 4819-3907 8726-8726 8726-4815 0-8721-3907-8721-8726 0-4815 3907-8721 8721-8721zm-85468-20825h68009c25537 0 46422 20782 46422 46178v68350c0 25395-20885 46174-46422 46174l-68009 1c-25537 0-46426-20778-46426-46174v-68352c0-25395 20889-46177 46426-46177z"
              fill="#ffffff"/>
        </svg>
        <span class="hidden md:block">Instagram</span>
      </a>
      <a href="https://www.youtube.com/channel/UCGHj33z66OdflbmJKglYBYg" target="_blank"
         class="social-link social-link-youtube">
        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333333 333333"
             shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality"
             fill-rule="evenodd" clip-rule="evenodd">
          <path
              d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm84195 132297s-1678-11849-6843-17052c-6545-6843-13873-6887-17223-7283-24036-1751-60138-1751-60138-1751h-63s-36085 0-60135 1751c-3363 409-10681 437-17223 7283-5168 5203-6811 17052-6811 17052s-1711 13904-1711 27838v13029c0 13905 1709 27837 1709 27837s1678 11849 6811 17061c6542 6843 15139 6621 18977 7350 13761 1314 58457 1710 58457 1710s36133-64 60169-1783c3363-397 10678-438 17223-7284 5168-5202 6843-17065 6843-17065s1711-13904 1711-27837v-13028c-35-13905-1745-27837-1745-27837l-9 9-1-1zm-102010 56674v-48312l46437 24237-46437 24075z"
              class="fill-current"/>
        </svg>
        <span class="hidden md:block">YouTube</span>
      </a>
      <a href="https://amazingradio.us/profile/maudiolabs" target="_blank" class="social-link social-link-amazingradio">
        <svg class="w-7 h-7" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
          <path d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm-4 29V15l12 9-12 9z" class="fill-current"/>
        </svg>
        <span class="hidden md:block">AmazingRadio</span>
      </a>
    </div>

    <div class="flex flex-row-reverse lg:flex-col gap-3 items-center lg:items-end justify-end">
      <img class="w-10 lg:w-20" src="/img/maudiolabs.png" alt="MAudioLABS"/>
      <p class="text-xs">&copy; All rights reserved <span>{{ new Date().getFullYear() }}</span></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="postcss">

.social-link {
  @apply flex items-center gap-2 cursor-pointer;

  * {
    transition: all 150ms ease;
    -webkit-transition: all 150ms ease;
    -moz-transition: all 150ms ease;
    -o-transition: all 150ms ease;
  }

  &.social-link-spotify {
    &:hover {
      svg {
        color: #1ed760;
      }
    }
  }

  &.social-link-facebook {
    &:hover {
      svg {
        color: #3b5998;
      }
    }
  }

  &.social-link-instagram:hover path {
    fill: url(#a)
  }

  &.social-link-youtube {
    &:hover {
      svg {
        color: red;
      }
    }
  }

  &.social-link-amazingradio {
    &:hover {
      svg {
        color: #F9EB57;
      }
    }
  }
}
</style>
