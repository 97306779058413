<template>
  <div id="waves-container">
    <svg class="wave" id="wave1">
      <defs></defs>
      <path d=""/>
    </svg>
    <svg class="wave" id="wave2">
      <defs></defs>
      <path d=""/>
    </svg>
    <svg class="wave" id="wave3">
      <defs></defs>
      <path d=""/>
    </svg>
  </div>
</template>

<script>
import {wavify} from '@/wavify'

export default {
  name: "Waves",

  data() {
    return {
      wave1: {},
      wave2: {},
      wave3: {},
    }
  },

  mounted() {
    this.initWave1();
    this.initWave2();
    this.initWave3();
  },

  methods: {
    initWave1() {
      this.wave1 = wavify(document.querySelector('#wave1 path'), {
        container: '#waves-container',
        height: 60,
        bones: 8,
        amplitude: 40,
        color: 'rgb(11,79,202,0.4)',
        speed: .45
      });
    },
    initWave2() {
      this.wave2 = wavify(document.querySelector('#wave2 path'), {
        container: '#waves-container',
        height: 80,
        bones: 6,
        amplitude: 60,
        // color: 'rgb(30,195,255, 0.2)',
        color: 'rgb(219,120,47, 0.4)',
        speed: .10
      });
    },
    initWave3() {
      this.wave3 = wavify(document.querySelector('#wave3 path'), {
        container: '#waves-container',
        height: 60,
        bones: 8,
        amplitude: 80,
        color: 'rgba(0,0,0,0.6)',
        speed: .25
      });
    },
  }
}
</script>

<style scoped>
#waves-container {
  @apply w-screen fixed bottom-0;
  height: 60vh;

  .wave {
    @apply w-full h-full absolute bottom-0;

    path {
      @apply w-full;
    }

    &#wave1 {
      z-index: 1;
    }

    &#wave2 {
      z-index: 2;
    }

    &#wave3 {
      z-index: 3;
    }
  }
}
</style>
