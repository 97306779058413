<template>
  <div id="page" v-cloak>
    <Navbar/>

      <router-view v-slot="slotProps">
        <transition name="fade-in-up" mode="out-in">
          <component :is="slotProps.Component"></component>
        </transition>
      </router-view>

    <Waves v-if="$route.path === '/tunes'"/>

    <Footer/>
  </div>
</template>

<script>
import '@/css/tailwind.css'
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import Waves from "@/components/Waves";

export default {
  name: 'App',

  components: {Footer, Navbar, Waves},

  mounted() {
    this.setViewHeight()
    window.addEventListener('resize', () => {
      this.setViewHeight()
    })
  },

  methods: {
    setViewHeight() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  }
}
</script>

<style lang="postcss">
[v-cloak] { display: none; }
#page {
  @apply w-screen h-screen overflow-hidden;

  background: url('/img/bg_3b.jpg') center center fixed;
  background-size: cover;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

.fade-in-up-leave-to {
  opacity: 0;
  transition: opacity .3s;
}

.fade-in-up-enter-from {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
}

.fade-in-up-enter-to {
  opacity: 0;
  animation-duration: .7s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

</style>
